import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useActions, useLanguage, languageList, localeDefaults, flatKeys, unpick, deepMerge,
} from '@touchlay/utils'

import { makeStyles } from '@material-ui/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreIcon from '@material-ui/icons/Restore'
import SaveIcon from '@material-ui/icons/Save'

import { RichTextInput, HelpTooltip } from '@touchlay/frontend-base'
import { useModal, useSnackbar } from '@touchlay/frontend-base/dist/utils'

import { generateID } from './treeUtils'

import { defaultProperty, properties, fieldNames } from './EditComponents'

const translatedFields = [
  'title', 'description', 'subtitle', 'src', 'text' ]

const defaultFields = [
  'id', 'type', 'title',
]

// TODO: re-enable based on roles
// const metaFields = [
//   'meta', 'meta.noMove', 'meta.noDelete', 'meta.locked', 'meta.hidden',
// ]

const defaultFieldsByType = {
  atom: [ 'hidden', 'title' ],
  'menu.item':
    [ 'hidden', 'title', 'description', 'point', 'media', 'backgroundMedia',
      'hideText', 'openAsPopup', 'options', 'options.size', 'children' ],
  'menu.header': [ 'hidden', 'variant', 'title', 'subtitle' ],
  article: [ 'hidden', 'title', 'description', 'icon', 'options', 'options.size' ],
  view: [ 'styleClass', 'backgroundColor', 'backgroundUrl' ],
  pdf: [ 'hidden', 'src', 'options', 'options.size' ],
  image: [ 'hidden', 'src', 'linkToId', 'options', 'options.size' ],
  video: [ 'hidden', 'src', 'muted', 'autoplay', 'loop', 'options', 'options.size' ],
  web: [ 'hidden', 'src', 'options', 'options.size' ],
}

const defaultFieldsByView = {
  ScatterView: [
    'options.smartAutoplay',
    'options.smartAutoplayOptions',
    'options.smartAutoplayOptions.playerCount',
    'options.smartAutoplayOptions.delayMin', 'options.smartAutoplayOptions.delayMax',
  ],
  GridView: [ 'options', 'options.size' ],
}

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '45%',
  },
  listItemFull: {
    flexDirection: 'column',
    alignItems: 'self-end',
  },
  inputContainerFull: {
    width: '100%',
  },
}))

// show the current language if we have a translatable field
const languageLabel = (lang, label, value) =>
  translatedFields.includes(label) && lang
    ? ` (${lang})`
    : ''

const formatLabel = label => {
  // add spaces before uppercase letters
  const spacedLabel = label.replace(/([A-Z])/g, ' $1').trim()
  // make first letter uppercase
  return spacedLabel.slice(0, 1).toUpperCase() + spacedLabel.slice(1)
}

const getProperty = (label, props) => {
  let propertyType = defaultProperty
  for (const propList of properties) {
    if (propList[label]) {
      if (propList[label]?.check) {
        if (propList[label].check(props)) {
          propertyType = propList[label]
          break
        }
      } else {
        // if we don't have a check function - we just pass it through
        propertyType = propList[label]
        break
      }
    }
  }
  return propertyType
}
function EditItem (props) {
  const { label, value, mode, node, localPath, path, disabled, parentNode } = props
  const lang = useLanguage()

  const propertyType = getProperty(label, { node, parentNode, localPath, path })

  if (!propertyType?.field) {
    return null
  }

  let info = null
  if (typeof propertyType?.info === 'function') {
    info = propertyType.info({ node, parentNode })
  } else if (typeof propertyType?.info === 'object') {
    info = propertyType.info
  }

  const formattedLabel = info?.name || formatLabel(label)

  info = {
    ...(info || {}),
    formattedLabel: formattedLabel + languageLabel(lang, label, value),
    disabled: disabled || node?.meta?.locked || (
      typeof info?.disabled === 'function'
        ? info.disabled({ mode, parentNode, node })
        : info?.disabled
    ),
    defaultValue:
      typeof info?.defaultValue === 'function'
        ? info.defaultValue({ mode, parentNode, node })
        : info?.defaultValue,
  }

  return (
    <propertyType.field
      {...props}
      extra={propertyType?.extra || {}}
      info={info}
    />
  )
}

const mergeArrays = (...arrs) => [...new Set(arrs.flat())]

/*
  Converts ['w1', 'w2', 'w3'] and ' > ' into 'w1 > w2 > w3'
*/
const prettyPrintArray = (parentName, mod) =>
  Array.isArray(parentName)
    ? parentName.reduce((xs, val) => `${xs.length > 0 ? `${xs}${mod}` : ''}${val}`, '')
    : undefined

const findFieldDetails = (path, localPath) => {
  if (!path || !localPath) {
    return null
  }
  for (const item of fieldNames) {
    if (item[path] && item[path].fullPath) {
      return item[path]
    }
    if (item[localPath] && !item[localPath].fullPath) {
      return item[localPath]
    }
  }
  return null
}

function FieldSection ({ fields, disabled, fieldData = {}, mode, parent = {}, setData, openSubpage, presentationStyle, node, treeData, parentNode }) {
  const { simpleFields, nestedFieldRoots, nestedFields } = fields.reduce((acc, field) => {
    if (translatedFields.includes(field)) { // this is a translated field -> special simple field
      acc.simpleFields.push(field)
      return acc
    }

    if (!field.includes('.') && fields.find(f => f.startsWith(field + '.'))) { // this is a nested field root
      acc.nestedFieldRoots.push(field)
    } else if (field.includes('.')) { // this is a nested field
      const parts = field.split('.')
      const root = parts.shift()
      const rest = parts.join('.')
      if (root in acc.nestedFields) { // existing root
        acc.nestedFields[root].push(rest)
      } else { // new root
        acc.nestedFields[root] = [rest]
      }
    } else { // this is a simple field
      acc.simpleFields.push(field)
    }
    return acc
  }, { simpleFields: [], nestedFieldRoots: [], nestedFields: {} })

  let parentName = prettyPrintArray(parent.name, ' > ') ?? 'General Information'
  const parentPathDot = prettyPrintArray(parent.path, '.')
  const parentLocalPath = prettyPrintArray(parent.localPath, '.')
  const fieldDetails = findFieldDetails(parentPathDot, parentLocalPath)

  // a clean field is any field with no simple fields on it (and no overrides)
  const cleanField = !fieldDetails && (parent.depth > 1 && simpleFields.length === 0)
  if (cleanField) {
    parentName = null
  }
  if (fieldDetails) {
    parentName = fieldDetails.name ?? null
  }
  return (
    <>
      {parentName === null
        ? null
        : parent.depth > 1
          ? <ListItem><ListItemText><b>{parentName}</b></ListItemText></ListItem>
          : <ListSubheader color='primary'>{parentName}</ListSubheader>}
      {simpleFields.map(k =>
        <EditItem
          disabled={disabled}
          key={k}
          label={k}
          localPath={parent.localPath ?? []}
          mode={mode}
          node={node}
          openSubpage={openSubpage}
          parentNode={parentNode}
          path={parent.path ?? []}
          presentationStyle={presentationStyle}
          setData={setData}
          treeData={treeData}
          value={fieldData[k]}
        />
      )}
      {nestedFieldRoots.map(k => {
        const property = getProperty(k, {
          node,
          parentNode,
          path: parent.path ?? [],
          localPath: parent.localPath ?? [],
        })
        if (property?.field === null) return null
        return (
            <FieldSection
            disabled={disabled}
            fieldData={fieldData[k]}
            fields={nestedFields[k]}
            key={k}
            mode={mode}
            node={node}
            openSubpage={openSubpage}
            parent={{
              key: k,
              path: [
                ...(parent.path ?? []),
                k,
              ],
              localPath: [
                ...(cleanField ? parent.localPath : []),
                k,
              ],
              name: [
                ...(cleanField ? parent.name : []),
                property.info?.name || formatLabel(k),
              ],
              depth: (parent.depth || 0) + 1,
            }}
            parentNode={parentNode}
            presentationStyle={presentationStyle}
            setData={(obj, options) =>
              setData(obj,
                { prefix: `${k}${options?.prefix ? `.${options.prefix}` : '.'}` }
              )}
            treeData={treeData}
          />
        )
      }
      )}
    </>
  )
}

const EmailSection = ({ node, disabled, layout, value, setData }) => {
  // only show section on views
  const isView = node?.type === 'view'
  if (!isView) return null
  // check if SendView is in layout
  const hasSendView = layout.find(view => view.view === 'SendView')
  if (!hasSendView) return null

  const handleChange = (html) =>
    setData({
      'email.content': html,
    })

  return (
    <>
      <ListSubheader color='primary'>Email</ListSubheader>
      <ListItem>
        <RichTextInput
          controls={[ 'title', 'bold', 'italic', 'link', 'clear' ]}
          defaultValue={value?.content}
          disabled={disabled}
          label='Email message content ...'
          onChange={handleChange}
        />
      </ListItem>
    </>
  )
}

export default function GenericEditView ({ data, mode, removeNode, treeData, updateData, openSubpage, openSubpageBatch, presentation, presentationStyle, parent, layout }) {
  const [ modalComponent, openModal ] = useModal()
  const { enqueueSnackbar } = useSnackbar()
  const styles = useStyles()
  const dispatch = useActions('#dashboard-editview#')
  const [ fieldData, setFieldData ] = useState(data)
  useEffect(() => {
    setFieldData(data)
  }, [data])

  const lang = useLanguage()
  const handleChangeLanguage = e => {
    dispatch({ type: 'config', key: 'lang', value: e.target.value })
  }

  const handleDelete = () => {
    openModal('confirm', {
      severity: 'warning',
      title: 'Delete Node & All Children',
      desc: 'Are you sure you want to delete this node and all its children? This action CANNOT be undone!',
      accept: () => {
        removeNode()
        if (parent?.id) {
          openSubpage('element', parent.id, true)
        } else {
          openSubpage(false)
        }
      },
    })
  }

  /*
    can't be used to modify id
  */
  // eslint-disable-next-line no-unused-vars
  const handleBulkUpdate = (obj) => {
    const newFieldData = deepMerge(fieldData, obj)
    if (obj.id) {
      newFieldData.id = generateID(obj.id, treeData, [], {
        userNode: newFieldData?._source?.kind === 'user',
      })
    }
    setFieldData(newFieldData)
    updateData(newFieldData, () => {
      if (obj.id) {
        openSubpageBatch('element', newFieldData.id, true) // -> replace history when ID changes
      }
    })
  }

  /*
    Notes on setData/:
      fieldData is used as local source of truth
      while editing, this is batched into a final update - such that multiple setData calls don't override each other (unless explicitly marked)

  */
  const dataBatchRef = useRef(null)
  const dataBatchUpdaterRef = useRef(null)

  const normalizeObj = (prefix, obj) => {
    let ret = {}
    for (const [ key, value ] of Object.entries(obj)) {
      ret = deepMerge(
        ret,
        unpick(prefix + key, value)
      )
    }
    return ret
  }
  const setData = (obj, options = {}) => {
    if (dataBatchRef.current === null) {
      dataBatchRef.current = {}
    }
    // if we're editing a source link on an user source, ignore it
    if (fieldData?._sourceLink && mode?.type === 'user' && 'id' in obj) {
      delete obj.id
    }
    if ('id' in obj) {
      obj.id = generateID(obj.id, treeData, [], {
        userNode: fieldData?._source?.kind === 'user',
      })
    }
    const objProc = normalizeObj(options.prefix || '', obj)
    dataBatchRef.current = deepMerge(dataBatchRef.current, objProc)

    let newFieldData = deepMerge(fieldData, dataBatchRef.current)
    if (newFieldData._template) {
      newFieldData = deepMerge(newFieldData, unpick('_modified', true))
    }

    // console.log({ type: 'setData', obj: dataBatchRef.current, newFieldData })
    setFieldData(newFieldData) // reflect on local state ASAP
    if (dataBatchUpdaterRef.current) {
      clearTimeout(dataBatchUpdaterRef.current)
    }
    dataBatchUpdaterRef.current = setTimeout(pushUpdates, 0)
  }
  const pushUpdates = () => {
    if (dataBatchRef.current) {
      const obj = dataBatchRef.current
      let newFieldData = deepMerge(fieldData, obj)

      if (newFieldData._template) {
        newFieldData = deepMerge(newFieldData, unpick('_modified', true))
      }
      // console.log({ type: 'pushUpdates', newFieldData })
      setFieldData(newFieldData)
      updateData(newFieldData, () => {
        if (obj.id) {
          openSubpageBatch('element', newFieldData.id, true) // -> replace history when ID changes
        }
      })
      dataBatchRef.current = null
    }
  }

  // ---- user components ----
  const enableUserComponents = () => {
    const { _source, ...rest } = fieldData
    handleBulkUpdate({
      _template: rest,
      _sourceLink: generateID(),
    })
  }
  const disableUserComponents = () => {
    handleBulkUpdate({
      _template: undefined,
      _sourceLink: undefined,
    })
  }
  const updateTemplate = () => {
    enqueueSnackbar('Template updated')
    const { type, _template, _sourceLink, expanded, _source, ...rest } = fieldData
    handleBulkUpdate({
      _template: {
        type: _template.type,
        ...rest,
      },
      _modified: false,
    })
  }

  const syncFromTemplate = () => {
    openModal('confirm', {
      severity: 'warning',
      title: 'Per-user components',
      desc: 'You will lose your local changes, do you want to reset back to the template?',
      accept: () => {
        const { _template } = fieldData
        const { id, ...rest } = _template
        enqueueSnackbar('Data was reset to template')
        handleBulkUpdate({
          ...rest,
          _template,
          _modified: false,
        })
      },
    })
  }

  // const _isOwnerOfUC = true

  const switchUserComponents = () => {
    if (fieldData._sourceLink) {
      openModal('confirm', {
        title: 'Convert to Global Section',
        desc: 'When making this section global, all changes that users have made in their own presentations will be lost. Are you sure you want to make this section global?',
        accept: disableUserComponents,
      })
    } else {
      openModal('confirm', {
        title: 'Convert to Per-User Section',
        desc: 'When making this section per-user, each user will be able ' +
              'to edit their own version of it. The current content will be' +
              ' used as a template. Are you sure you want to make this section per-user?',
        accept: enableUserComponents,
      })
    }
  }

  // -----------------------------------

  // `type` and `id` are always fields, the rest are found by type (& view type if it applies)
  const fields = mergeArrays(
    defaultFields,
    defaultFieldsByType[fieldData.type] || [],
    fieldData.type === 'view' ? (defaultFieldsByView[fieldData.view] ?? []) : [],
    flatKeys(fieldData)
    // metaFields, // TODO: re-enable based on roles
  ).filter(f =>
    f !== 'expanded' // remove `expanded` key from field
  )

  const auth = useSelector(x => x.auth)
  const isOwner = presentation?.users?.find(u => u._id === auth?.user?._id)?.roles.includes('OWNER')
  const hasSourceLink = fieldData?._sourceLink
  //  const isUserSource = fieldData?._source?.kind === 'user'
  const hasUserSource = (x) => {
    if (x?._sourceLink || x?._source?.kind === 'user') {
      return true
    }
    if (Array.isArray(x?.children)) {
      return x.children.some(hasUserSource)
    }
    return false
  }
  const userComponentsAvailable = hasSourceLink ? true : !hasUserSource(fieldData)
  let disabled = false
  if (mode?.type === 'user') {
    if (fieldData?._source?.kind === 'main' && !hasSourceLink) {
      disabled = true
    }
  }
  const languages = presentation?.config?.languages || localeDefaults
  const langsAugm = languages.map(lang => {
    const full = languageList.find(x => x.tag === lang)
    return full || { name: lang }
  })
  return (
    <>
      {modalComponent}
      <List>
        <ListSubheader color='primary'>Translation</ListSubheader>
        <ListItem>
          <ListItemText>
            Language
          </ListItemText>
          <ListItemSecondaryAction className={styles.inputContainer}>
            <Select
              fullWidth
              onChange={handleChangeLanguage}
              value={lang}
            >
              {langsAugm.map(lang => (
                <MenuItem key={lang.tag} value={lang.tag}>{lang.name}</MenuItem>
              ))}
              {/* TODO: implement adding more languages, re-use from settings */}
            </Select>
          </ListItemSecondaryAction>
        </ListItem>
        <FieldSection
          disabled={disabled}
          fieldData={fieldData}
          fields={fields}
          mode={mode}
          node={data}
          openSubpage={openSubpage}
          parentNode={parent}
          presentationStyle={presentationStyle}
          setData={setData}
          treeData={treeData}
        />
        <EmailSection
          disabled={disabled}
          layout={layout}
          node={data}
          setData={setData}
          value={data.email}
        />
        {userComponentsAvailable && (
          <>
            <ListSubheader color='primary'>Per-user versions</ListSubheader>
            <ListItem key={'user-comp-1'}>
              <ListItemText>
                Per-user versions <HelpTooltip text={'allow each user to have their own version of this component and its contents'} />
              </ListItemText>
              <ListItemSecondaryAction className={styles.inputContainer}>
                <Switch
                  checked={!!data._sourceLink}
                  color='primary'
                  disabled={!isOwner || disabled}
                  onChange={switchUserComponents}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {data._sourceLink && (
              <>
                <ListItem>
                  <ListItemText>
                    <Button
                      color='inherit'
                      disabled={!isOwner || disabled}
                      onClick={updateTemplate}
                      startIcon={<SaveIcon />}
                      style={{ width: '100%' }}
                      variant='outlined'
                    >
                      update template
                    </Button>
                  </ListItemText>
                  <ListItemText>
                    <Button
                      color='inherit'
                      onClick={syncFromTemplate}
                      startIcon={<RestoreIcon />}
                      style={{ width: '100%' }}
                      variant='outlined'
                    >
                      reset / load from template
                    </Button>
                  </ListItemText>
                </ListItem>
              </>
            )}
          </>
        )}
        <ListSubheader color='primary'>Delete</ListSubheader>
        <ListItem>
          <ListItemText>
            <Button
              color='inherit'
              disabled={disabled || (hasSourceLink && !isOwner)}
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              style={{ width: '100%' }}
              variant='outlined'
            >
              delete this {data?.type === 'view' ? 'layout' : 'element'}
            </Button>
          </ListItemText>
        </ListItem>
      </List>
    </>
  )
}
